<template lang="pug">
div
  downgrade-info-bar
  .d-flex.pt-2(v-if="showPeriodSelect")
    .d-inline-flex.align-items-center.brand-settings-toggle(
      @click="setPeriod(selectedPeriod === 12 ? 1 : 12)"
    )
      div(:class="{ selected: selectedPeriod === 12 }")
        span {{ $t('plan.annual') }}
      div(:class="{ selected: selectedPeriod === 1 }")
        span {{ $t('plan.monthly') }}

  template(v-if="couponDetails && couponDetails.hasValidCoupon")
    .brand-pricing-table-coupon-details-wrapper
      .brand-pricing-table-coupon-details
        span {{ couponDetails.couponDescription }}
        om-tooltip.info-tooltip(
          v-if="couponDetails.couponValidityPeriod"
          theme="dark"
          placement="top-start"
          color="#1A7457"
        )
          span {{ $t('couponDetails.validityPeriod') }}:<br>{{ couponDetails.couponValidityPeriod.start }} - {{ couponDetails.couponValidityPeriod.end }}

  .row.mt-5(
    style="justify-content: center"
    :class="!isPaying && !isShopifyPayment && !isEnterpriseOrMaster && !hasFuturePackage ? 'mt-5' : 'mt-3 highlighted'"
  )
    .col-6.col-md-4.brand-col-lg-5(v-for="plan in planListToShow")
      .plan-card.mb-4(
        :class="{ current: (isPlanSameAsCurrent(plan.name) && !isInactive && hasPaymentMethod) || isEnterpriseOrMasterPlan(plan.name) || hasFuturePackage }"
      )
        .title.text-center {{ getPlainPlanName(plan.name) }}
        .plan-body
          template(v-if="hasDiscountForPlan(plan)")
            .font-weight-bold.text-center.plan-card-discounted-price {{ getPlanOriginalPriceString(plan) }} /
              span.plan-card-billing.font-weight-normal {{ $t(selectedPeriodLanguageKey) }}
          .plan-card-price {{ planPrice(plan) }}
            span.plan-card-billing.font-weight-normal / {{ $t(selectedPeriodLanguageKey) }} {{ $t('additionalVAT') }}
          .plan-card-annual-price(v-if="getCalculatedMonthlyPrice(plan)") {{ getCalculatedMonthlyPrice(plan) }} / {{ $t('month') }}
          .text-center.desc(v-html="getPlanDescription(plan)")
          template(v-if="canCalculateCorrectUpgradePrice")
            om-button.mt-4.mx-2(
              primary
              v-if="isShopifyPayment && isPlanSameAsCurrent(plan.name) && needsShopifyPlanUpdateWithNewPrice"
              @click="$emit('shopifyNextBillingUpdate', plan.name, 'recurring_update', 'plan-table')"
            ) {{ $t('newCampaignFlow.needsPlanUpdate.withNewPriceShort') }}
            om-button.mt-4.mx-2(
              primary
              v-else-if="isBankTransfer && showPlanSelectButton(plan.name)"
              @click="showBankTransferModal(plan.name, selectedPeriod)"
            ) {{ $t('plan.select') }}
            om-button.mt-4.mx-2(
              primary
              v-else-if="showPlanSelectButton(plan.name)"
              @click="planSelected(plan.name)"
            ) {{ $t('plan.select') }}
            .mt-4.mx-auto.plan-text-inactive(v-else-if="isPlanSameAsCurrent(plan.name)") {{ $t('plan.current') }}

  .row.mt-4(style="justify-content: center")
    template(v-if="!canCalculateCorrectUpgradePrice")
      div {{ $t('plan.masterEnterprise.contact') }}
        a.brand-link(href="mailto:support@optimonk.com") {{ $t('plan.masterEnterprise.support') }}

  bank-transfer-change-plan
</template>

<script>
  import BankTransferChangePlan from '@/components/Modals/BankTransferChangePlan.vue';
  import { isNotFreemiumPackage } from '@om/payment/src/helpers/planDetails';
  import DowngradeInfoBar from '@/components/DowngradeInfoBar';
  import { mapState, mapGetters, createNamespacedHelpers } from 'vuex';
  import { separatePrice } from '@/util';
  import dateFormat from '@/mixins/dateFormat';

  const {
    mapGetters: paymentGetters,
    mapState: paymentState,
    mapMutations: paymentMutations,
  } = createNamespacedHelpers('payment');

  export default {
    components: {
      DowngradeInfoBar,
      BankTransferChangePlan,
    },
    mixins: [dateFormat],
    props: {
      showPeriodSelect: { type: Boolean, required: true },
      canCalculateCorrectUpgradePrice: { type: Boolean, required: true },
      couponDetails: { type: Object, default: null },
    },
    data() {
      return {};
    },
    computed: {
      ...mapState(['partnerTypeService']),
      ...mapGetters([
        'isPageViewBasedPackageByPlan',
        'getRegion',
        'isAccountExpired',
        'currentPlan',
        'accountFeatures',
        'currentPeriod',
      ]),
      ...paymentState(['selectedPlan', 'selectedPeriod', 'plans']),
      ...paymentGetters([
        'getPlainPlanName',
        'isInactive',
        'isShopifyPayment',
        'isBraintreePayment',
        'isEnterpriseOrMaster',
        'isEnterpriseOrMasterPlan',
        'hasPaymentMethod',
        'isBankTransfer',
        'getDateExpires',
        'isPlanExpired',
        'isPlanSameAsCurrent',
        'needsShopifyPlanUpdateWithNewPrice',
        'hasFuturePackage',
      ]),

      planListToShow() {
        if (this.hasFuturePackage) {
          return [this.plans[0]];
        }
        return this.filterAllowedPlans(this.plans);
      },

      isPaying() {
        return this.$store.getters.isPaying && !this.isInactive && this.hasPaymentMethod;
      },

      planPrice() {
        return function (plan) {
          return this.getPlanPriceString(plan);
        };
      },

      selectedPeriodKey() {
        let period = 'monthly';
        if (this.selectedPeriod === 12) {
          period = 'annual';
        } else if (this.selectedPeriod === 3) {
          period = 'quarterly';
        }
        return period;
      },

      selectedPeriodLanguageKey() {
        let period = 'month';
        if (this.selectedPeriod === 12) {
          period = 'annual';
        } else if (this.selectedPeriod === 3) {
          period = 'quarter';
        }
        return period;
      },

      getPlanDescription() {
        return (plan) => {
          if (this.isPageViewBasedPackageByPlan(plan.name)) {
            return this.$t('freemiumPlanDescription', {
              maxSites: plan.maxDomains,
              noOfPageViews: this.addThousandSep(plan.maxVisitors),
            });
          }
          if (
            this.partnerTypeService.isResellerPartnerType() ||
            this.partnerTypeService.isCustom()
          ) {
            return this.$t('agencyPlanDescription', {
              noOfVisitors: this.addThousandSep(plan.maxVisitors),
            });
          }

          return this.$t('planDescription', {
            maxDomains: plan.maxDomains,
            noOfVisitors: this.addThousandSep(plan.maxVisitors),
          });
        };
      },

      hasPayingPlan() {
        return this.$store.getters.isPaying;
      },
      isChangingPeriod() {
        return this.currentPeriod !== this.selectedPeriod;
      },
    },
    methods: {
      ...paymentMutations(['setPeriod', 'setPlan']),
      showPlanSelectButton(planName) {
        if (this.isChangingPeriod) {
          return true;
        }
        if (this.isPaying && !this.isPlanSameAsCurrent(planName)) {
          return true;
        }
        if (!this.isPaying || this.isPlanExpired) {
          return true;
        }
        if (this.isShopifyPayment && (this.isInactive || !this.isPlanSameAsCurrent(planName))) {
          return true;
        }

        return false;
      },
      filterAllowedPlans(plans) {
        if (this.isShopifyPayment && isNotFreemiumPackage(this.currentPlan, true)) {
          return plans.filter((plan) => plan.name === this.currentPlan);
        }
        return plans.filter((plan) => {
          return !['business', 'basic', 'professional'].includes(plan.name);
        });
      },
      addThousandSep(value) {
        return this.$options.filters.thousandSep(value);
      },

      async planSelected(planName) {
        this.$emit('planSelected', planName);
      },

      showBankTransferModal(planName) {
        this.setPlan(planName.toLowerCase());
        this.$modal.show('bank-transfer-change-plan');
      },

      getPlanPriceString(plan) {
        return this.formatPrice(plan.price[this.selectedPeriodKey]);
      },

      hasDiscountForPlan(plan) {
        return plan.hasDiscount && plan.hasDiscount[this.selectedPeriodKey];
      },

      getPlanOriginalPriceString(plan) {
        if (!plan.originalPrice || !plan.originalPrice[this.selectedPeriodKey]) return null;
        return this.formatPrice(plan.originalPrice[this.selectedPeriodKey]);
      },

      getCalculatedMonthlyPrice(plan) {
        if (this.selectedPeriod !== 12 || !plan.calculatedPrice?.monthlyPriceWithAnnual) {
          return null;
        }
        return this.formatPrice(plan.calculatedPrice?.monthlyPriceWithAnnual);
      },

      formatPrice(price) {
        const { prefix, priceNumber, suffix } = separatePrice(price);

        return `${prefix}${this.$options.filters.thousandSep(priceNumber, ' ')} ${suffix}`;
      },
    },
  };
</script>
